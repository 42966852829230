import lazy from './js/lazy';
import { resizeAnimationStopper, scrollEvents, scrollTo, updateTime, tabs } from './js/utils';
import typography from './js/typography';
import header from './js/header';
import htmxEvents from './js/htmx';
import scrollable from './js/scrollable';
//import map from './js/map';
import mapD3 from './js/map-d3';
import jsAccordion from './js/accordion.js';
import slider from './js/slider';
import keyAnchors from './js/keyAnchors.js';

window.onload = function() {
  scrollEvents();
  resizeAnimationStopper();
  scrollTo();
  updateTime();
  tabs();

  scrollable.init();
  htmxEvents.init();
  lazy.init();
  typography.init();
  header.init();
  //map.init();
  mapD3();
  jsAccordion.init();
  slider.init();
  keyAnchors.init();

  
};

htmx.on("htmx:afterSwap", function(evt) {
  scrollEvents();
  scrollTo();
  tabs();
  
  lazy.init();
  typography.restart();
  // map.init();
  mapD3();
  jsAccordion.init();
  slider.init();
  keyAnchors.init();


  const sectionHandle = document.querySelector('.js-section-handle').value;

  if (sectionHandle) {
    switch (sectionHandle) {
      case 'photographicEssayItems':
        document.querySelector('.js-header').classList.add('--margin-right');
        document.querySelector('.js-footer-nav').classList.add('--margin-right');
        
        break;
      
      default:
        document.querySelector('.js-header').classList.remove('--margin-right');
        document.querySelector('.js-footer-nav').classList.remove('--margin-right');
        break;
    }
  }



  /*
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  */
});