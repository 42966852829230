import * as d3 from "d3";
import regression from 'regression';

const rightConstraint = function() {
    const windowWidth = window.innerWidth;

    if (windowWidth < 1000) {
        return 0;
    }
    if (windowWidth > 2000) {
        return -2800;
    }

    const data = [
        [1000, 0],
        [1100, -900],
        [1200, -1100],
        [1300, -1340],
        [1400, -1590],
        [1500, -1750],
        [1600, -1850],
        [1700, -2100],
        [1800, -2500],
        [1900, -2650],
        [2000, -2800],
    ];

    // Perform linear regression
    const result = regression.linear(data);

    console.log(result);

    // Display the slope and y-intercept
    const slope = result.equation[0];
    const intercept = result.equation[1];
    console.log('Slope:', slope);
    console.log('Intercept:', intercept);

    const numericWidth = Number(windowWidth);
    const value = slope * numericWidth + intercept;

    return value;
};

const defaultR = 40;
const defaultTextFontSize = 70;
const minZoom = 1;
const maxZoom = 3;

let $mapGroup = null, zoom = null, width = null, height = null;

let overlappingGroups = [];

async function insertSVGIntoDiv(url, containerId) {
  try {
    // Fetch the SVG file
    const response = await fetch(url);
    const svgContent = await response.text();
    const $container = document.getElementById(containerId);
    
    if (!$container) {
      return;
    }

    // Insert the SVG content into the specified div
    document.getElementById(containerId).innerHTML = svgContent;
  } catch (error) {
    console.error('Error fetching SVG:', error);
  }
}

function initZoom() {
	d3.select('svg')
		.call(zoom);
}

function handleZoom(e) {
  d3.select('svg g#map-group')
    .attr('transform', e.transform);

  d3.select('svg g#map-regions')
    .selectAll('g')
    .attr('transform-origin', 'center center')
    .attr('transform', `scale(${1/e.transform.k})`);
    detectOverlaps();

  d3.select('svg')
    .selectAll('.js-map-content-marker')
    .attr('r', function() {
      if (this.classList.contains('--group')) {
        return calcGroupRadius(this.getAttribute('data-qty')) / e.transform.k;
      }
      return defaultR / e.transform.k;
    });

  d3.select('svg')
    .selectAll('.js-map-content-label')
    .style('font-size', function() {
      return defaultTextFontSize / e.transform.k + 'px';
    });
  

    // update --strokeWidth variable according to zoom level
    const strokeWidth = 3 / e.transform.k;
    document.documentElement.style.setProperty('--strokeWidth', strokeWidth + 'px');

    const strokeDasharray = 20 / e.transform.k;
    document.documentElement.style.setProperty('--strokeDasharray', strokeDasharray + 'px');
}

function calcGroupRadius(group) {

    const qty = group.length;

    let radius = 60;

    if (qty > 4) radius = 70;
    if (qty > 9) radius = 80;
    if (qty > 14) radius = 90;
    if (qty > 19) radius = 95;
    if (qty > 24) radius = 100;
    if (qty > 29) radius = 105;

    const r = radius;
    console.log(r)
    return r;
}

function addMarkers() {
  document.querySelectorAll('.js-map-info-marker').forEach(function(el) {
        let x = el.getAttribute('data-x');
        let y = el.getAttribute('data-y');

        // convert % to px
        x = x * width / 100;
        y = y * height / 100;

        d3.select('svg g#map-group')
        .append('circle')
        .attr('cx', x)
        .attr('cy', y)
        .attr('r', defaultR)
        .attr('data-label', el.getAttribute('data-label'))
        .attr('data-location-slug', el.getAttribute('data-location-slug'))
        .attr('data-id', el.getAttribute('data-id'))
        .attr('class', 'js-map-content-marker js-map-location-trigger');
    });
}

function isOverlapping(el1, el2) {
  const rect1 = el1.getBoundingClientRect();
  const rect2 = el2.getBoundingClientRect();

  return !(rect1.right < rect2.left || 
           rect1.left > rect2.right || 
           rect1.bottom < rect2.top || 
           rect1.top > rect2.bottom);
}

function detectOverlaps() {
  const markers = d3.selectAll('.js-map-content-marker').nodes();

  // create groups with overlapping markers
  overlappingGroups = [];

  // remove all group circles and labels
  d3.selectAll('.js-map-content-marker.--group').remove();
  d3.selectAll('.js-map-content-label').remove();

  markers.forEach(function(marker) {
    let overlaps = false;

    overlappingGroups.forEach(function(group) {
      if (overlaps) {
        return;
      }

      group.forEach(function(groupMarker) {
        if (overlaps) {
          return;
        }

        if (isOverlapping(marker, groupMarker)) {
          overlaps = true;
          group.push(marker);
        }
      });
    });

    if (!overlaps) {
      overlappingGroups.push([marker]);
    }
  });

  // add a class to each marker in an overlapping group with more than 1 marker
  // also create a new circle for each group, in the center of the group
  overlappingGroups.forEach(function(group) {
    if (group.length > 1) {
      group.forEach(function(marker) {
        d3.select(marker).classed('is-overlapping', true);
      });

      // get the center of the group with all elements cx and cy attributes
      const groupCenterX = group.reduce((sum, el) => sum + parseFloat(el.attributes.cx.value), 0) / group.length;
      const groupCenterY = group.reduce((sum, el) => sum + parseFloat(el.attributes.cy.value), 0) / group.length;

    
      d3.select('svg g#map-group')
        .append('circle')
        .attr('cx', groupCenterX)
        .attr('cy', groupCenterY)
        .attr('r', calcGroupRadius(group))
        .attr('data-qty', group.length)
        .attr('class', 'js-map-content-marker --group');

      d3.select('svg g#map-group')
        .append('text')
        .attr('x', groupCenterX)
        .attr('y', groupCenterY)
        .attr('dy', '0.3em')
        .attr('class', 'js-map-content-label')
        .text(group.length)
        .style('fill', 'white')
        .style('font-size', defaultTextFontSize + 'px')
        .style('text-anchor', 'middle')
        .style('alignment-baseline', 'baseline')

      // on click, zoom in on the group
      document.querySelector('.js-map-content-marker.--group').addEventListener('click', function() {

        d3.select('svg')
          .transition()
          .duration(750)
          .call(zoom.transform, d3.zoomIdentity
            .translate(width / 2, height / 2)
            .scale(3)
            .translate(-groupCenterX, -groupCenterY));
      });
      

    } else {
      d3.select(group[0]).classed('is-overlapping', false);
    }
  });
}

async function main() {
    await insertSVGIntoDiv('/map.svg', 'map');

    $mapGroup = document.getElementById('map-group');

    if (!$mapGroup) {
        return;
    }

    width = $mapGroup.getBBox().width;
    height = $mapGroup.getBBox().height;

    zoom = d3.zoom()
        .scaleExtent([minZoom, maxZoom])
        .translateExtent([[rightConstraint(), 0], [width, height]])
        .on('zoom', handleZoom);

    // Move map to the right position

    initZoom();
    handleZoom({transform: d3.zoomIdentity});
    addMarkers();
    detectOverlaps();

    d3.select('svg').call(zoom.translateBy, rightConstraint() * -1, 0);

    document.querySelector('#map').classList.add('is-loaded');

    document.querySelector('.js-map-zoom-in').addEventListener('click', function() {
        d3.select('svg')
            .transition()
            .duration(750)
            .call(zoom.transform, d3.zoomIdentity
                .translate(width / 2, height / 2)
                .scale(maxZoom)
                .translate(-width / 2, -height / 2))
    });

    document.querySelector('.js-map-zoom-out').addEventListener('click', function() {
        d3.select('svg')
            .transition()
            .duration(750)
            .call(zoom.transform, d3.zoomIdentity
                .translate(-rightConstraint(), 0)
                .scale(minZoom))
    });


    document.querySelectorAll('.js-map-location-trigger').forEach(($location) => {
        $location.addEventListener('click', function() {

            // remove 'is-active' class from all locations
            document.querySelectorAll('.js-map-location-trigger').forEach(($location) => {
                $location.classList.remove('is-active');

                const locationSlug = $location.getAttribute('data-location-slug');
                const $popup = document.querySelector('.js-map-popup[data-location-slug="' + locationSlug + '"]');
                $popup.classList.remove('is-active');
            });

            $location.classList.toggle('is-active');
            
            const locationSlug = $location.getAttribute('data-location-slug');
            const $popup = document.querySelector('.js-map-popup[data-location-slug="' + locationSlug + '"]');
            $popup.classList.toggle('is-active');
        })
    });

    document.querySelectorAll('.js-map-popup-close').forEach(($close) => {
        $close.addEventListener('click', function() {

            
            // remove 'is-active' class from all locations
            const markerId = $close.getAttribute('data-id');
            const $trigger = document.querySelector('.js-map-location-trigger[data-id="' + markerId + '"]');
            if ($trigger) {
                $trigger.classList.remove('is-active');
            
                const locationSlug = $trigger.getAttribute('data-location-slug');
                const $popup = document.querySelector('.js-map-popup[data-location-slug="' + locationSlug + '"]');
                $popup.classList.remove('is-active');
            }


        })
    });
}

export default main;
