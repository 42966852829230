const elements = {
  typography: '.js-typography',
  typographyHeader: '.js-typography-header',
  typographyFooter: '.js-typography-footer',
  textWhite: '.js-text-white',
};

export default {
    init: function(){
      setTimeout(() => {
        const self = this;
        const typographyElement = document.querySelector(elements.typography);
        if (!typographyElement) return;
        
        window.addEventListener('resize', self.fullCalc, true);
        window.dispatchEvent(new Event('resize'));
      }, 100);
    },
  
    fullCalc: function(){
      const fontSize = 16;

      const typographyElement = document.querySelector(elements.typography),
        typographyHeaderElement = document.querySelector(elements.typographyHeader),
        siteHeaderHeight = document.querySelector('.js-header').clientHeight;


      typographyHeaderElement.style.fontSize = fontSize + 'px';
  
      const fixedFooterElement = document.querySelector('.js-footer-fixed');


      while (true) {

        // const isInViewport = (typographyElementHeight) <= ((window.innerHeight || document.documentElement.clientHeight) - (fixedFooterElement.clientHeight * .5));

        const typographyElementRect = typographyElement.getBoundingClientRect();
        const fixedFooterElementRect = fixedFooterElement.getBoundingClientRect();
        const isInViewport = typographyElementRect.bottom <= fixedFooterElementRect.top - (siteHeaderHeight * 0.25);

        console.log(isInViewport, typographyElementRect.bottom, fixedFooterElementRect.top);

        typographyHeaderElement.style.fontSize = (parseFloat(typographyHeaderElement.style.fontSize) + 0.1) + 'px';    
  
        if (!isInViewport) {  
          // typographyHeaderElement.style.fontSize = (parseFloat(typographyHeaderElement.style.fontSize) * 0.99) + 'px';
          setTimeout(() => {
            typographyElement.classList.add('is-visible');
          }, 500);
          break;
        } 
      }
    },
    restart: function(){
      const self = this;
  
      window.removeEventListener('resize', self.fullCalc, true);
      self.init();
    }
  };