
export default {
    elements: {
      lazy: 'js-lazy',
      inline: 'js-lazy-inline',
    },
    init: function() {
      const self = this;
  
      const options = {
        elements_selector: '.' + self.elements.lazy,
        threshold: 1000,
        class_loaded: 'is-loaded',
        callback_loaded: function(el) {
          el.parentNode.classList.add('is-loaded');
        }
      };
  
      let lazyLoadInstance = new LazyLoad(options);
    }
  };