

export default {
    init() {
        const $main = document.querySelector('.js-main');

        document.body.addEventListener('keydown', function (e) {
    
            const $asides = document.querySelectorAll('.js-aside');
            if (!$asides) return;

            const $asidesReverse = Array.from($asides).reverse();

            const keyCode = e.keyCode;

            const currentScrollPosition = $main.scrollTop;
                
            // if top, check what id "#aside-X" is current and scroll to the previous one
            if (keyCode == 38) {
                let found = false;
                $asidesReverse.forEach(function($aside) {
                    const asideId = parseInt($aside.id.split('-')[1]);
                    const asideTop = $aside.offsetTop;
        
                    if (asideTop < currentScrollPosition && !found) {
                        found = true;

                        const previousId = asideId;
                        const $previousAside = document.querySelector(`#aside-${previousId}`);
                        if (!$previousAside) return;
                        
                        $main.scrollTo({
                            top: $previousAside.offsetTop,
                            behavior: 'smooth'
                        });
                    }
                });
            }

            // if bottom, check what id "#aside-X" is current and scroll to the next one
            if (keyCode == 40) {
                let found = false;
                $asides.forEach(function($aside) {
                    const asideId = parseInt($aside.id.split('-')[1]);
                    const asideTop = $aside.offsetTop;
        
                    if (asideTop > currentScrollPosition && !found) {
                        found = true;
                        const nextId = asideId;

                        const $nextAside = document.querySelector(`#aside-${nextId}`);
                        if (!$nextAside) return;

                        $main.scrollTo({
                            top: $nextAside.offsetTop,
                            behavior: 'smooth'
                        });
                    }
                });
            }
        });   
    }
};