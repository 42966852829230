

export function resizeAnimationStopper() {
    let resizeTimer;
    window.addEventListener("resize", function() {
    document.querySelector('body').classList.add("resize-animation-stopper");
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
        document.querySelector('body').classList.remove("resize-animation-stopper");
    }, 400);
    });
};

export function scrollTo() {
    const scrollTo = document.querySelectorAll('.js-scroll-to');

    scrollTo.forEach((el) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();
            const id = el.getAttribute('href').replace('#', '');
            const top = document.querySelector(`#${id}`).offsetTop;
            const offset = el.getAttribute('data-offset') | 0;

            document.querySelector('.js-main').scrollTo({
                top: (top || 0) - offset,
                behavior: "smooth"
            });

            return false;
        });
    });
};

export function updateTime() {
    setInterval(update, 500);
    function update() {
        let str;
        const now = new Date();
        const timeElement = document.querySelector('.js-time');

        if (!timeElement) return;

        str = now.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'});
        document.querySelector('.js-time').innerHTML = str;
    }
};

export function scrollEvents() {

    const $jsMain = document.querySelector('.js-main');
    const $jsHscroll = document.querySelector('.js-hscroll');

    if ($jsMain) {
        $jsMain.addEventListener('scroll', function(e) {
            jsMain();
        });
    }

    if ($jsHscroll) {
        $jsHscroll.addEventListener('scroll', function(e) {
            jsHscroll();
        });
    }

    function jsMain() {
        const target = document.querySelector('.js-main');
        const scrollEvent01 = document.querySelector('.js-scroll-event-01');

        if (scrollEvent01) {
            if (target.scrollTop > 50) {
                scrollEvent01.classList.add('opacity-0');
                scrollEvent01.classList.add('pointer-events-none');
            } else {
                scrollEvent01.classList.remove('opacity-0');
                scrollEvent01.classList.remove('pointer-events-none');
            }
        }
    }

    function jsHscroll() {
        const target = document.querySelector('.js-hscroll');

        if (!target) return;
        
        const windowWidth = window.innerWidth;
        const hScrollPercentage = target.scrollLeft / (windowWidth) * 100;
    }
};

export function tabs() {
    const elements = {
        trigger: document.querySelectorAll('.js-tabs-trigger'),
        target: document.querySelectorAll('.js-tabs-target'),
    };

    if (!elements.trigger) return;

    elements.trigger.forEach((el) => {

        el.addEventListener('click', (e) => {
            e.preventDefault();
            const target = el.getAttribute('href').replace('#', '');

            elements.trigger.forEach((el) => {
                el.classList.remove('is-active');
            });

            elements.target.forEach((el) => {
                el.classList.remove('is-active');
            });

            el.classList.add('is-active');
            document.querySelector(`#${target}`).classList.add('is-active');
        });
    }
)};

