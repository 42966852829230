import Swiper, { Navigation, Keyboard, Mousewheel } from 'swiper';

export default {
    elements: {
      slider: '.js-slider',
      counter: '.js-slider-counter',
      slide: '.js-slider-slide',
      element01: '.js-slider-element-01',
    },
    init: function() {
      const self = this;
      const swiper = new Swiper(self.elements.slider, {
        speed: 500,
        followFinger:false,
        shortSwipes : true,
        moveStartThreshold:false,
        modules: [
            Navigation,
            Keyboard,
            Mousewheel,
        ],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        mousewheel: {
            invert: false,
        },
        
      });

      swiper.on('slideChange', function (swiper) {
        
        const $counter = document.querySelector(self.elements.counter);

        const currentSlide = swiper.slides[swiper.activeIndex];

        if (currentSlide.dataset.sliderImageIndex && $counter) {
            $counter.innerHTML = currentSlide.dataset.sliderImageIndex;
        }

        if (swiper.activeIndex < 1 || swiper.progress == 1) {

            document.querySelectorAll('.sequence-arrow--grow').forEach((el) => {
                el.classList.add('--is-larger');
            });
        } else {

            document.querySelectorAll('.sequence-arrow--grow').forEach((el) => {
                el.classList.remove('--is-larger');
            });

        }

        console.log('swiper.activeIndex', swiper.activeIndex);
        console.log('swiper.progress', swiper.progress);


        if (swiper.activeIndex >= 1) {
            document.querySelectorAll('.js-text-white').forEach((el) => {
                el.classList.remove('is-active');
            });

            // document.querySelector('.js-slider-element-01').classList.add('opacity-0');
            // document.querySelector('.js-slider-element-02').classList.add('opacity-0');
            document.querySelector('.js-header').classList.remove('--margin-right');
            document.querySelector('.js-footer-nav').classList.remove('--margin-right');
        } else {
            document.querySelectorAll('.js-text-white').forEach((el) => {
                el.classList.add('is-active');
            });

            // document.querySelector('.js-slider-element-01').classList.remove('opacity-0');
            // document.querySelector('.js-slider-element-02').classList.remove('opacity-0');
            document.querySelector('.js-header').classList.add('--margin-right');
            document.querySelector('.js-footer-nav').classList.add('--margin-right');
        }

        if (swiper.progress == 1) {
            if (document.querySelector('.js-counter'))
                document.querySelector('.js-counter').classList.add('opacity-0');
            
            document.querySelector('.js-sidenav').classList.add('opacity-0');
            document.querySelectorAll('.js-arrow-label').forEach((el) => {
                el.classList.remove('opacity-0');
            });
        } else {
            if (document.querySelector('.js-counter'))
                document.querySelector('.js-counter').classList.remove('opacity-0');
                
            document.querySelector('.js-sidenav').classList.remove('opacity-0');
            document.querySelectorAll('.js-arrow-label').forEach((el) => {
                el.classList.add('opacity-0');
            });
        }

      });



      document.body.addEventListener('keydown', function (e) {
        const keyCode = e.keyCode;

        if (keyCode == 38) {
            
            const $link = document.querySelector('.sequence-arrow--north');

            if ($link) {
                $link.click();
            }
        }

        if (keyCode == 40) {

            const $link = document.querySelector('.sequence-arrow--south');

            if ($link) {
                $link.click();
            }
        }
      });
    }
  };