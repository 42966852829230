
export default {
    elements: {
      header: '.js-header',
      navToggle: '.js-header-navToggle',
      modalToggle: '.js-header-modalToggle',
    },
    init: function() {
      const self = this;
  
      document.querySelectorAll(self.elements.navToggle).forEach(function(element) {
        element.addEventListener('click', function(e) {
          e.preventDefault();
  
          self.toggle('nav');
        });
      });
    },
    getScrollbarWidth: function() {
      return window.innerWidth - document.documentElement.clientWidth;
    },
    close: function(slug) {
      const self = this;
      document.documentElement.style.setProperty('--scrollbar-width', self.getScrollbarWidth() + 'px');
  
  
      document.querySelector(self.elements.header).classList.remove('with-' + slug);
      document.documentElement.classList.remove('with-' + slug);
      
    },
    toggle: function(slug) {
      const self = this;
      document.documentElement.style.setProperty('--scrollbar-width', self.getScrollbarWidth() + 'px');
  
      document.querySelector(self.elements.header).classList.toggle('with-' + slug);
      document.documentElement.classList.toggle('with-' + slug);
  
    },
  };